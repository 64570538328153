/*@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap'); /* Import a creative font */

.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.card-img-overlay {
  background: rgba(0, 0, 0, 0.4); /* Dark overlay for better text readability */
}

.card-title-overlay, .card-text-overlay {
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Text shadow for depth */
}

.card-title-overlay {
  font-family: 'Pacifico', cursive; /* Use the creative font */
  font-size: 3rem;
  animation: pulseText 2s infinite; /* Add animation */
}

.card-text-overlay {
  font-family: 'Poppin', cursive;
  font-size: 1.5rem;
  animation: pulseText 2s infinite;
}

.word span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes pulseText {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.butterfly {
  position: fixed;
  width: 100px; /* Adjust the size as needed */
  transition: transform 0.5s ease-out;
}

.container {
  text-align: center;
}

img.card-img {
  height: 100vh; /* Make the image cover the full viewport height */
  object-fit: cover; /* Ensure the image covers the entire area */
}
/* Add this to your global CSS file */
.silhouette-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Behind other content */
  overflow: hidden;
}

.dancing-silhouette {
  position: absolute;
  width: 150px;
  animation: dance 10s infinite ease-in-out alternate;
}

@keyframes dance {
  0% {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(100px) translateY(-50px) scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: translateX(-100px) translateY(100px) scale(1);
    opacity: 1;
  }
}
.footer {
  background-color: #f8e1a1; /* Light golden color */
  color: #333; /* Darker text for contrast */
  text-align: center;
  padding: 20px 0;
  margin-top: 30px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 300;
}

.footer-content p {
  margin: 0;
}

