.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Optional background color */
  z-index: 9999; /* Keep on top of other elements */
}

.loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 50px; /* Adjust font size to your preference */
  font-weight: bold;
  color: #e5cb0b; /* Change color as needed */
}

.letter {
  animation: bounce 1.5s infinite ease-in-out;
}

/* Delay the animation for each letter */
.letter:nth-child(1) {
  animation-delay: -0.3s;
}
.letter:nth-child(2) {
  animation-delay: -0.15s;
}
.letter:nth-child(3) {
  animation-delay: 0s;
}

/* Bounce Animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust bounce height */
  }
}
