/* ContactPage.css */

body {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.container {
  max-width: 1200px;
  padding: 0 15px; /* Add padding for smaller screens */
}

h1 {
  font-weight: bold;
  color: #333;
}

hr {
  border-top: 2px solid #ddd;
  width: 50%;
  margin: 0 auto;
}

h3 {
  color: #333;
  margin-bottom: 20px;
}

.instructor-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 80px; /* Add padding to move content to the right */
}

.instructor-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.instructor-info h4 {
  margin: 0;
  font-size: 1.2rem;
}

.instructor-info p {
  margin: 10px 0;
}

.instructor-info a {
  color: #007bff;
  text-decoration: none;
}

.instructor-info a:hover {
  text-decoration: underline;
}

.map-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #333;
  text-decoration: none;
}

.social-media a:hover {
  color: goldenrod;
}

.text-center {
  text-align: center;
}

.mr-3 {
  margin-right: 2rem;
}

@media (max-width: 767px) {
  .container {
    padding: 0 10px; /* Reduce padding on smaller screens */
  }

  .instructor-info {
    padding-left: 0;
    align-items: center; /* Center align items on smaller screens */
  }

  .instructor-image {
    width: 150px;
    height: 150px;
  }

  .mr-3 {
    margin-right: 0;
    margin-bottom: 1rem; /* Add bottom margin for spacing */
  }

  .map-container {
    padding-bottom: 56.25%; /* Maintain aspect ratio for map */
    margin-top: 20px; /* Add margin for better spacing */
  }

  .row {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center;
    gap: 20px; /* Add gap between elements */
  }

  .col-md-6 {
    width: 100%; /* Ensure full width for columns on smaller screens */
  }

  h3 {
    margin-top: 20px; /* Add top margin for better spacing */
  }
}
